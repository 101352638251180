import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Politicas/Politicas.js";


function MapaSitio() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <div id="servicio" style={{ backgroundColor: "#f9f9f9" }}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="text-center title" style={{ color: "#031425" }}>
                  MAPA DEL SITIO<br></br>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Col md="12">
                      <h3><b style={{ color: "#080f61" }}>Páginas</b></h3>
                      <ul>
                        <a href="/#servicios"><li >Autenticaciones</li></a>
                        <a href="/transparencia/informacionPublica"><li>Usuarios Corporativos</li></a>
                        <a href="/#contacto"><li>Contactenos</li></a>
                        <a href="/cotizador"><li>Cotizador</li></a>
                        <a href="/#servicios"><li>Escrituración</li></a>
                        <a href="/"><li>Inicio</li></a>
                        <a href="/MapaSitio"><li>Mapa del sitio</li></a>
                        <a href="/mision_vision"><li>Misión y Visión</li></a>
                        <a href="/notario"><li>Notario</li></a>
                        <a href="/#cotizador"><li>Noticias</li></a>
                        <a href="/participa"><li>Participa</li></a>
                        <a href="/procesos"><li>Procesos</li></a>
                        <a href="/#servicios"><li>Registro Civil</li></a>
                        <a href="/tramite"><li>Servicios</li></a>
                        <a href="/tarifa"><li>Tarifas</li></a>
                        <a href="/transparencia/informacionPublica"><li>Transparencia</li></a>
                      </ul>
                    </Col>
                    <Col md="12">
                      <h3><b style={{ color: "#080f61" }}>Posts por categoría</b></h3>
                      <ul>
                        <a href="/transparencia"><li >Conocenos</li></a>
                        <ul>
                          <a href="/mision_vision"><li>Misión y visión</li></a>
                          <a href="/transparencia/funcionesYdeberes"><li>Funciones y deberes</li></a>
                          <a href="/procesos"><li>Procesos y procedimientos</li></a>
                          <a href="/transparencia/organigrama"><li>Organigrama</li></a>
                          <a href="/#cotizador"><li>Noticias</li></a>
                          <a href="/notario"><li>Notario</li></a>
                        </ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/tramite"><li >Tramites</li></a>
                        <ul>
                          <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf"><li>Información</li></a>
                          <a href="/tramite"><li>Servicios</li></a>
                          <a href="/tarifa"><li>Tarifas</li></a>
                        </ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/procesos"><li>Información de la Entidad</li></a>
                        <ul><a href="/procesos"><li>Mapas y cartas descriptivas de los procesos</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/transparencia"><li>Directorio de entidades</li></a>
                        <ul>
                          <a href="https://www.minjusticia.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                          <a href="https://www.supernotariado.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                          <a href="https://www.registraduria.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                          <a href="https://www.procuraduria.gov.co/portal/"><li>Ministerio de Justicia y del Derecho</li></a>
                          <a href="https://www.vur.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                          <a href="https://www.suin-juriscol.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                        </ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/transparencia"><li>Directorio de agremiaciones, asociaciones y otros grupos de interés</li></a>
                        <ul><a href="/transparencia/agremiaciones"><li>Directorio de agremiaciones, asociaciones y otros grupos de interés</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/transparencia"><li>Servicio al público, normas, formularios y protocolos de atención</li></a>
                        <ul>
                          <a href="/normativa"><li>Normas</li></a>
                          <a href="/formato"><li>Formularios</li></a>
                          <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf"><li>Protocolos de Atención</li></a>
                        </ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/procesos"><li>Procedimientos que se siguen para tomar decisiones en las diferentes áreas</li></a>
                        <ul><a href="/procesos"><li>Procedimientos que se siguen para tomar decisiones en las diferentes áreas</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/AfectarPublico"><li>Información sobre decisiones que pueden afectar al público</li></a>
                        <ul><a href="/AfectarPublico"><li>Información sobre decisiones que pueden afectar al público</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/transparencia/entesControl"><li>Entes y autoridades que lo vigilan</li></a>
                        {/* ------------------------------------------------------------ */}
                        <a href="/normativa"><li>Normativa</li></a>
                        <ul><a href="/normativa"><li>Normativa</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/transparencia"><li>Informes a organismos de inspección, vigilancia y control</li></a>
                        <ul><a href="/transparencia"><li>Informes a organismos de inspección, vigilancia y control</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/transparencia"><li>Planes de mejoramiento</li></a>
                        <ul><a href="/transparencia"><li>Planes de Mejoramiento vigentes exigidos por los entes de control o auditoría externos o internos</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/transparencia"><li>Informes de gestión, evaluación y auditoría</li></a>
                        <ul><a href="/transparencia"><li>Formatos y/o formularios asociados</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/GestionInformacion"><li>Instrumentos de gestión de la información</li></a>
                        <ul><a href="/GestionInformacion"><li>Instrumentos de gestión de la información</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/ClasificadaReservada"><li>Índice de información clasificada y reservada</li></a>
                        <ul><a href="/ClasificadaReservada"><li>Índice de información clasificada y reservada</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/GestionDocumental"><li>Programa de gestión documental</li></a>
                        <ul><a href="/GestionDocumental"><li>Programa de gestión documental</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/RetencionDocumental"><li>Tablas de retención documental</li></a>
                        <ul><a href="/RetencionDocumental"><li>Tablas de retención documental</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/transparencia"><li>Información para niños, niñas y adolescentes</li></a>
                        <ul><a href="/transparencia"><li>Información para niños, niñas y adolescentes</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                        <a href="/Mujeres"><li>Información para Mujeres</li></a>
                        <ul><a href="/Mujeres"><li>Información para Mujeres</li></a></ul>
                        {/* ------------------------------------------------------------ */}
                      </ul>

                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </div>
      {/* <Tramites modal={Modal3} setModal={() => setModal3(false)} /> */}
    </>

  );

}

export default MapaSitio;
