
import EdictoItem from '../EdictoItem'

const EdictoList = ({ items }) => {
  return <>
    <div className="row">
      {items.map(({ id, ...item }) => <EdictoItem key={id} {...item} />
      )}
    </div>
  </>
}

export default EdictoList