import { useState, useEffect } from 'react'

import {
  Container,
  Row,
  Col,
} from "reactstrap";

import axios from 'axios'

import { EdictoList } from "./components";

// const data = [
//   { id: 1, titulo: 'Special title treatment', nombre_cliente: 'sofia torres', fecha_inicio: '2021-08-07', fecha_expiracion: '2021-08-07', ruta_archivo: require('assets/pdf/1. PLANTILLA COMPRAVENTA SIN P.H. CONTADO (V.2020).pdf'), },
//   { id: 2, titulo: 'Special title treatment', nombre_cliente: 'sofia torres', fecha_inicio: '2021-08-07', fecha_expiracion: '2021-08-07', ruta_archivo: require('assets/pdf/2  PLANTILLA COMPRAVENTA CON P H  DE CONTADO (V 2020).pdf'), },
//   { id: 7, titulo: 'Special title treatment', nombre_cliente: 'sofia torres', fecha_inicio: '2021-08-07', fecha_expiracion: '2021-08-07', ruta_archivo: require('assets/pdf/2.9.pdf'), },
//   { id: 3, titulo: 'Special title treatment', nombre_cliente: 'sofia torres', fecha_inicio: '2021-08-07', fecha_expiracion: '2021-08-07', ruta_archivo: require('assets/pdf/9.1 C 3.pdf'), },
//   { id: 4, titulo: 'Special title treatment', nombre_cliente: 'sofia torres', fecha_inicio: '2021-08-07', fecha_expiracion: '2021-08-07', ruta_archivo: require('assets/pdf/11.2 C ITEM PQ-05 V.2  Satisfacción al Usuario Analisis de Datos.pdf'), },
//   { id: 5, titulo: 'Special title treatment', nombre_cliente: 'sofia torres', fecha_inicio: '2021-08-07', fecha_expiracion: '2021-08-07', ruta_archivo: require('assets/pdf/Condiciones de Uso.pdf'), },
//   { id: 6, titulo: 'Special title treatment', nombre_cliente: 'sofia torres', fecha_inicio: '2021-08-07', fecha_expiracion: '2021-08-07', ruta_archivo: require('assets/pdf/00755-Tarifas Notariales SDN.pdf'), },
// ]

const Edictos = () => {
  const [items, setItem] = useState([])

  useEffect(() => {
    const config = {
      mode: 'cors',
      headers: { Authorization: "Bearer " + process.env.REACT_APP_TOKEN_API },
    }

    axios.get(process.env.REACT_APP_UrlApi+'edictos', config).then(( edicto ) => {
      let edictos = [];
      edicto.data.forEach((data)=>{
        if(Date.parse(data.fecha_expiracion)> Date.now()){
          edictos.push(data);
        }
      })
      setItem(edictos)
    }).catch(error => {
      console.log(error)
    })
  }, []);

  return <>
    <div className="wrapper">
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <Container className="fluid">
          <Row className="justify-content-center">
            <Col lg="12">
              <h1 className="title text-center"><b style={{ color: "#ef7e2d" }}>Edictos</b></h1>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <EdictoList items={items} />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </>
}

export default Edictos