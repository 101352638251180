import { useState } from "react";

import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container
} from "reactstrap";

import { Link } from "react-router-dom";

import Funciones from "../../views/Molda/Funciones.js";
import Organigrama from "../../views/Molda/Organigrama.js";
import Tramites from "../../views/Molda/Tramites.js";
import logo from "../../../src/assets/img/NOTARIA/logo.png";



const DropdownWhiteNavbar = () => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container className="d-flex justify-content-center">
          <div className="navbar-translate flex-grow-1">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img src={logo} width="150" height="50" alt="Logo de la notaria 26 de bogotá" />
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar className="flex-grow-0">
            <Nav id="ceva" navbar>
              <NavItem>
                <NavLink href="/">
                  <p id="texto">Inicio</p>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <p id="texto">Servicios en linea</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" end>
                  <DropdownItem to="/cotizador" tag={Link}>
                    <p style={{ fontSize: "16px" }}>Cotizador</p>
                  </DropdownItem>
                  <DropdownItem to="/" tag={Link}>
                    <p style={{ fontSize: "16px" }}>Usuarios corporativos</p>
                  </DropdownItem>
                  <DropdownItem to="/edictos" tag={Link}>
                    <p style={{ fontSize: "16px" }}>Ver Edictos</p>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <p id="texto">Conócenos</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" end>
                  <DropdownItem to="/mision_vision" tag={Link}>
                    <p style={{ fontSize: "16px" }}>Misión y Visión</p>
                  </DropdownItem>
                  <DropdownItem onClick={() => setOpen('first')}>
                    <p style={{ fontSize: "16px" }}>Funciones y deberes</p>
                  </DropdownItem>
                  <DropdownItem onClick={() => setOpen('second')}>
                    <p style={{ fontSize: "16px" }}>Organigrama</p>
                  </DropdownItem>
                  <DropdownItem to="/#cotizador" tag={Link}>
                    <p style={{ fontSize: "16px" }}>Noticias</p>
                  </DropdownItem>
                  <DropdownItem to="/notario" tag={Link}>
                    <p style={{ fontSize: "16px" }}>Notario</p>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <p id="texto">Trámites</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" end>
                  <DropdownItem onClick={() => setOpen('third')}>
                    <p style={{ fontSize: "16px" }}>Información</p>
                  </DropdownItem>
                  <DropdownItem to="/tramite" tag={Link}>
                    <p style={{ fontSize: "16px" }}>Servicios</p>
                  </DropdownItem>
                  <DropdownItem to="/tarifa" tag={Link}>
                    <p style={{ fontSize: "16px" }}>Tarifas</p>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/#contacto">
                  <p id="texto">Contáctenos</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/transparencia/0">
                  <p id="texto">Transparencia</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/participa">
                  <p id="texto">PARTICIPA</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/pagos">
                  <p id="texto">PAGOS</p>
                </NavLink>
              </NavItem>
              {/*<NavItem>
                <NavLink href="http://notariavirtual.notaria26bogota.com.co/">
                  <p id="texto">Notaría Virtual</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <GoogleTranslate />
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Funciones modal={open === 'first'} setModal={() => setOpen(false)} />
      <Organigrama modal={open === 'second'} setModal={() => setOpen(false)} />
      <Tramites modal={open === 'third'} setModal={() => setOpen(false)} />
    </>
  );
}

export default DropdownWhiteNavbar;
