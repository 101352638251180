import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody
} from "reactstrap";
// core components



function Normativa() {

  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="title text-center"><b style={{color: "#ef7e2d"}}>Normativa </b>que regula la entidad</h1>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="6">
                <a href="https://www.suin-juriscol.gov.co/" target='_black'>
                  <h6 className="info-title textot" style={{ cursor: "pointer" }}><b className="subItem">Enlace a Sistema Único de Información Normativa – SUIN.</b></h6>
                </a>
               </Col>  
            </Row> 
            <Row>
              <Col md="3">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h4 className="text-center">Decreto 960 de 1970</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html" 
                      role="button" className="btn btn-warning" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h5 className="text-center">Decreto 1069 de 2015 Sector Justicia y del Derecho</h5>
                    <a href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174" 
                      style={{marginBottom: "25px"}}
                        role="button" className="btn btn-warning"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h4 className="text-center">Decreto 1260 de 1970</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_1260_1970.html#:~:text=El%20estado%20civil%20de%20una,asignaci%C3%B3n%20corresponde%20a%20la%20ley" 
                      role="button" className="btn btn-warning" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h4 className="text-center">Decreto 2668 de 1988</h4>
                    <a href="http://www.suin-juriscol.gov.co/viewDocument.asp?id=1478913" 
                        role="button" className="btn btn-warning"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h4 className="text-center">Ley 1564 de 2012</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1564_2012.html"
                    style={{marginBottom: "40px"}} 
                      role="button" className="btn btn-warning" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h4 className="text-center">Ley 588 de 2000</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/ley_0588_2000.html" 
                      style={{marginBottom: "40px"}}
                        role="button" className="btn btn-warning"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h4 className="text-center">Decreto 1069 de 2015</h4>
                    <a href="http://www.suin-juriscol.gov.co/viewDocument.asp?id=30019870" 
                      role="button" className="btn btn-warning" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h4 className="text-center">Ley 1996 de 2019</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1996_2019.html"
                        style={{marginBottom: "40px"}} 
                        role="button" className="btn btn-warning"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h4 className="text-center">Decreto 1000 de 2015</h4>
                    <a href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=61836" 
                      role="button" className="btn btn-warning" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Normativa;