
import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Alert,
    Button,
} from "reactstrap";

const Pqrs = (props) => {
    const recaptchaRef = React.createRef();
    const [terminosNotaria, setTerminosNotaria] = useState(props.terminosNotaria)
    const [nombreNotaria, setNombreNotaria] = useState(props.nombreNotaria)
    const [colores, setColores] = useState(props.color)
    const [tipoPersona, setPersona] = useState('');
    const [email, setEmail] = useState('');
    const [fijo, setFijo] = useState('');
    const [celular, setCelular] = useState('');
    const [direccion, setDireccion] = useState('');
    const [tipoSolicitud, setSolicitud] = useState('');
    const [tipoRespuesta, setRespuesta] = useState('');
    const [detalle, setdetalle] = useState('');
    const [archivo, setArchivo] = useState('');
    const [pais, setPais] = useState('');
    const [paises, setPaises] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [departamento, setDepartamento] = useState('');
    const [municipios, setMunicipios] = useState([]);
    const [municipio, setMunicipio] = useState('');
    const [solicitudes, setSolicitudes] = useState([]);

    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [mensajeAyuda, setMensajeAyuda] = React.useState({ mostrar: false, tipo: '', message: '' });

    /*natural*/
    const [tipoDocumento, setDocumento] = useState('');
    const [identificacion, setIdentificacion] = useState('');
    const [primerNombre, setPrimernombre] = useState('');
    const [segundoNombre, setSegundonombre] = useState('');
    const [primerApellido, setPrimerapellido] = useState('');
    const [segundoApellido, setSegundoapellido] = useState('');

    useEffect(() => {
        const consultar = async () => {
            let config = {
                method: 'GET',
                mode: 'cors',
                headers: { "Access-Control-Allow-Origin": true, Authorization: "Bearer " + process.env.REACT_APP_TOKEN_API },

            }

            const resultado = await Axios.get(process.env.REACT_APP_UrlApi + 'paises', config)

            if (resultado.status === 200) {


                setPaises(resultado.data)

            }
        }

        consultar()
    }, []);

    useEffect(() => {
        const consultar = async () => {
            let config = {
                method: 'GET',
                mode: 'cors',
                headers: { "Access-Control-Allow-Origin": true, Authorization: "Bearer " + process.env.REACT_APP_TOKEN_API },

            }

            const resultado = await Axios.get(process.env.REACT_APP_UrlApi + 'tipo_solicitud', config)

            if (resultado.status === 200) {

                setSolicitudes(resultado.data)

            }
        }

        consultar()
    }, []);

    const consultarDepto = async (idPais) => {
        setPais(idPais)
        if (idPais === "46" || idPais === "Colombia") {
            let config = {
                method: 'GET',
                mode: 'cors',
                headers: { "Access-Control-Allow-Origin": true, Authorization: "Bearer " + process.env.REACT_APP_TOKEN_API },

            }

            const resultado = await Axios.get(process.env.REACT_APP_UrlApi + 'departamentos', config)

            if (resultado.status === 200) {

                setDepartamentos(resultado.data)

            }
        }
    }

    const consultarMunic = async (idDepartamento) => {
        setDepartamento(idDepartamento)

        let config = {
            method: 'GET',
            mode: 'cors',
            headers: { "Access-Control-Allow-Origin": true, Authorization: "Bearer " + process.env.REACT_APP_TOKEN_API },
        }

        const resultado = await Axios.get(process.env.REACT_APP_UrlApi + 'municipios/' + idDepartamento, config)

        if (resultado.status === 200) {

            setMunicipios(resultado.data)

        }

    }

    const archivar = async (e) => {
        let arch = e.target
        if (arch.files.length > 3) {
            alert("Solo puede subir 3 archivos como máximo, si desea más puede crear un archivo .zip/.rar")
            return
        }
        for (let index = 0; index < arch.files.length; index++) {
            let element = arch.files[index];
            var allowedExtensions = /(.jpg|.jpeg|.gif|.png|.xls|.xlsx|.doc|.docx|.pdf|.zip|.rar)$/i;
            if (!allowedExtensions.exec(element.name)) {
                alert('Please upload file having extensions .jpeg/.jpg/.png/.gif only.');
            } else {
                setArchivo(arch.files)
            }
        }
    }

    const validacion = () => {

        if (tipoSolicitud === '') return 'No ha definido su tipo de solicitud';
        if (tipoPersona === '') return 'Debe seleccionar un tipo de persona';
        if (tipoDocumento === '') return 'Debe seleccionar un municipio';
        if (identificacion === '') return 'Debe escribir su número de identificación';
        if (primerNombre === '') return 'Debe escribir su primer nombre';
        if (primerApellido === '' && tipoPersona !== 'juridica') return 'Debe escribir su primer apellido';
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(email)) return 'Correo electrónico no válido';
        if (fijo === '') return 'Falta un número telefónico fijo';
        if (celular === '') return 'Falta un número celular';
        if (direccion === '') return 'No ha escrito una dirección';
        if (pais === '') return 'Debe seleccionar un país';
        if (departamento === '') return 'Debe seleccionar un departamento';
        if (municipio === '') return 'Debe seleccionar un municipio';
        if (tipoRespuesta === '') return 'Debe indicar un tipo de respuesta';
        if (check1 === false) return 'Debe estar de acuerdo con los términos y condiciones';
        if (check2 === false) return 'Debe certificar los datos de contacto';
        if (detalle === '') return 'Para enviar necesita escribir escribir los detalles';
        if (archivo.length > 3) return 'No puede enviar más de tres documentos adjuntos';
        if (recaptchaRef.current.getValue() === '') return 'Debe seleccionar, No soy un robot';
        return "";
    }

    const enviarCorreo = async (e) => {
        e.preventDefault()
        setMensajeAyuda({ mostrar: true, tipo: 'warning', message: 'estamos enviado la Solicitud, en un momento daremos su radicado' })

        let mensajeError = "";
        console.log(recaptchaRef.current.getValue())

        mensajeError = validacion()
        if (mensajeError !== "") {
            setMensajeAyuda({ mostrar: true, tipo: 'danger', message: mensajeError })

            setTimeout(() => {
                setMensajeAyuda({ mostrar: false, tipo: 'danger', message: mensajeError })
            }, 8000)

        } else {
            try {
                const formData = new FormData();
                formData.append('tipo_solicitud', tipoSolicitud)
                formData.append('tipo_solicitante', tipoPersona)
                formData.append('tipo_identificacion', tipoDocumento)
                formData.append('numero_identificacion', identificacion)
                formData.append('primer_nombre', primerNombre)
                formData.append('segundo_nombre', segundoNombre)
                formData.append('primer_apellido', primerApellido)
                formData.append('segundo_apellido', segundoApellido)
                formData.append('correo_electronico', email)
                formData.append('telefono_fijo', fijo)
                formData.append('telefono_movil', celular)
                formData.append('direccion_correspondencia', direccion)
                formData.append('pais', pais)
                formData.append('departamento', departamento)
                formData.append('ciudad', municipio)
                formData.append('medio_respuesta', tipoRespuesta)
                formData.append('detalle', detalle)

                if (archivo.length > 3) {
                    setMensajeAyuda({ mostrar: true, tipo: 'danger', message: 'No puede subir mas 3 archivos' })
                }
                else if (archivo.length > 0) {
                    let index = 0
                    while (index < 3) {
                        let cambio = index + 1
                        formData.append('documento_url' + cambio, archivo[index])
                        index++
                    }
                }

                let config = {
                    method: 'POST',
                    mode: 'cors',
                    headers: { "Access-Control-Allow-Origin": true, Authorization: "Bearer " + process.env.REACT_APP_TOKEN_API },
                }

                const resultado = await Axios.post(process.env.REACT_APP_UrlApi + 'pqr', formData, config)

                if (resultado.status === 201) {
                    setMensajeAyuda({ mostrar: true, tipo: 'success', message: "Su número de radicado es: " + resultado.data.id })
                    setTimeout(() => {
                        setMensajeAyuda({ mostrar: false, tipo: 'danger', message: resultado.error })
                    }, 8000)
                } else {
                    setMensajeAyuda({ mostrar: true, tipo: 'danger', message: resultado.error })
                    setTimeout(() => {
                        setMensajeAyuda({ mostrar: false, tipo: 'danger', message: resultado.error })
                    }, 8000)

                }

            } catch (error) {
                setMensajeAyuda({ mostrar: true, tipo: 'danger', message: JSON.stringify(error.response.data.error) })
                setTimeout(() => {
                    setMensajeAyuda({ mostrar: true, tipo: 'danger', message: JSON.stringify(error.response.data.error) })
                }, 8000)
            }
        }

    }

    return (
        <>
            <Row>
                <Col lg="12">
                    <h1 className="title text-center"><b style={{ color: "#ef7e2d" }}>Registro</b> PQRS</h1>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Card>
                        <h6 className="title text-center textot">
                            <a href="https://www.procuraduria.gov.co/portal/pqrsdf_Solicitud_de_informacion_con_identificacion_reservada.page" target="_blank" rel="noreferrer" style={{ color: "#2c2c2c" }}>
                                Para realizar una solicitud de información pública con identidad reservada (click aquí)
                            </a>
                        </h6>
                        <h6 className="text-center info-title textot">
                            <a href={require("assets/pdf/11.2 C ITEM PQ-05 V.2  Satisfacción al Usuario Analisis de Datos.pdf")} rel="noreferrer" target="_blank" style={{ color: "#2c2c2c" }}>
                                Documento explicativo y de ayuda (click aquí)
                            </a>
                        </h6>
                        <CardTitle>
                            <h5 className="text-center">Los campos marcados con <b style={{ color: "Red" }}>*</b> son obligatorios.</h5>
                        </CardTitle>
                        <CardBody>

                            {/*
                .########..#######..########..##.....##.......##..
                .##.......##.....##.##.....##.###...###.....####..
                .##.......##.....##.##.....##.####.####.......##..
                .######...##.....##.########..##.###.##.......##..
                .##.......##.....##.##...##...##.....##.......##..
                .##.......##.....##.##....##..##.....##.......##..
                .##........#######..##.....##.##.....##.....######
                */}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="tipopersona"><b style={{ color: "Red" }}>*</b> Tipo de solicitante</label>
                                    <select className="form-control" id="tipopersona" value={tipoPersona} name="tipoPersona" onChange={e => setPersona(e.target.value)}>
                                        <option>Seleccione tipo de solicitante</option>
                                        <option value="natural">Persona natural</option>
                                        <option value="juridica">Persona juridica</option>
                                        <option value="niños">Niños,niñas y adolecentes</option>
                                        <option value="apoderado">Apoderado</option>
                                    </select>
                                </div>
                            </form>
                            <p className="text-center"><b style={{ color: colores }}> Formulario N°1</b></p>
                        </CardBody>
                    </Card>
                    {/*
            .########..#######..########..##.....##.....#######.
            .##.......##.....##.##.....##.###...###....##.....##
            .##.......##.....##.##.....##.####.####...........##
            .######...##.....##.########..##.###.##.....#######.
            .##.......##.....##.##...##...##.....##....##.......
            .##.......##.....##.##....##..##.....##....##.......
            .##........#######..##.....##.##.....##....#########
            */}
                    {/*
                ::::    :::     ::: ::::::::::: :::    :::              :::     :::::::::
                :+:+:   :+:   :+: :+:   :+:     :+:    :+:            :+: :+:   :+:    :+:
                :+:+:+  +:+  +:+   +:+  +:+     +:+    +:+           +:+   +:+  +:+    +:+
                +#+ +:+ +#+ +#++:++#++: +#+     +#+    +:+          +#++:++#++: +#++:++#+
                +#+  +#+#+# +#+     +#+ +#+     +#+    +#+          +#+     +#+ +#+
                #+#   #+#+# #+#     #+# #+#     #+#    #+#          #+#     #+# #+#
                ###    #### ###     ### ###      ########           ###     ### ###
            */}
                    {(tipoPersona === 'natural' || tipoPersona === 'apoderado') &&
                        <Card>
                            <CardBody>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="tipodocumento"><b style={{ color: "Red" }}>*</b> Tipo de identificación</label>
                                        <select className="form-control" id="tipodocumento" value={tipoDocumento} name="tipoDocumento" onChange={e => setDocumento(e.target.value)}>
                                            <option>Seleccione un tipo de documento</option>
                                            <option value="CC" >Cédula de ciudadanía</option>
                                            <option value="CE" >Cédula de extranjería</option>
                                            <option value="PAS" >Pasaporte</option>
                                            <option value="Otro">Otro</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="identi"><b style={{ color: "Red" }}>*</b> Número de identificación</label>
                                        {(tipoDocumento === 'CC' || tipoDocumento === 'CE') &&
                                            <input className="form-control" type="number" placeholder="Número de identificación" value={identificacion} onChange={e => setIdentificacion(e.target.value)} name="identificacion"></input>
                                        }
                                        {(tipoDocumento === 'PAS' || tipoDocumento === 'Otro') &&
                                            <input className="form-control" type="text" value={identificacion} onChange={e => setIdentificacion(e.target.value)} name="identificacion"></input>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="primer_nombre"><b style={{ color: "Red" }}>*</b>  Primer Nombre</label>
                                        <input className="form-control" type="text" value={primerNombre} placeholder="Primer Nombre" onChange={e => setPrimernombre(e.target.value)} name="primernombre"></input>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="segundo_nombre">Segundo Nombre</label>
                                        <input className="form-control" type="text" value={segundoNombre} placeholder="Segundo Nombre" onChange={e => setSegundonombre(e.target.value)} name="segundonombre"></input>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="primer_apellido"><b style={{ color: "Red" }}>*</b>  Primer Apellido</label>
                                        <input className="form-control" type="text" value={primerApellido} placeholder="Primer Apellido" onChange={e => setPrimerapellido(e.target.value)} name="primerapellido"></input>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="segundo_apellido">Segundo Apellido</label>
                                        <input className="form-control" type="text" value={segundoApellido} placeholder="Segundo Apellido" onChange={e => setSegundoapellido(e.target.value)} name="segundopellido"></input>
                                    </div>
                                </form>
                                <p className="text-center"><b style={{ color: colores }}> Formulario N°2</b></p>
                            </CardBody>
                        </Card>
                    }
                    {/*
                ::::    ::: ::::::::::: ::::::::   ::::::::
                :+:+:   :+:     :+:    :+:    :+: :+:    :+:
                :+:+:+  +:+     +:+    +:+    +:+ +:+
                +#+ +:+ +#+     +#+    +#+    +:+ +#++:++#++
                +#+  +#+#+#     +#+    +#+    +#+        +#+
                #+#   #+#+#     #+#    #+#    #+# #+#    #+#
                ###    #### ########### ########   ########
            */}
                    {(tipoPersona === 'niños') &&
                        <Card>
                            <CardBody>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="tipodocumento"><b style={{ color: "Red" }}>*</b> Tipo de identificación</label>
                                        <select className="form-control" id="tipodocumento" value={tipoDocumento} name="tipoDocumento" onChange={e => setDocumento(e.target.value)}>
                                            <option>Seleccione un tipo de documento</option>
                                            <option value="CE" >Cédula de extranjería</option>
                                            <option value="RC" >Registro Civil</option>
                                            <option value="TI" >Tarjeta de Identidad</option>
                                            <option value="PAS" >Pasaporte</option>
                                            <option value="Otro">Otro</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="identi"><b style={{ color: "Red" }}>*</b> Número de identificación</label>
                                        {(tipoDocumento === 'RC' || tipoDocumento === 'CE' || tipoDocumento === 'TI') &&
                                            <input className="form-control" type="number" value={identificacion} placeholder="Número de identificación" onChange={e => setIdentificacion(e.target.value)} name="identificacion"></input>
                                        }
                                        {(tipoDocumento === 'PAS' || tipoDocumento === 'Otro') &&
                                            <input className="form-control" type="text" value={identificacion} onChange={e => setIdentificacion(e.target.value)} name="identificacion"></input>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="primer_nombre"><b style={{ color: "Red" }}>*</b>  Primer Nombre</label>
                                        <input className="form-control" type="text" value={primerNombre} placeholder="Primer Nombre" onChange={e => setPrimernombre(e.target.value)} name="primernombre"></input>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="segundo_nombre">Segundo Nombre</label>
                                        <input className="form-control" type="text" value={segundoNombre} placeholder="Segundo Nombre" onChange={e => setSegundonombre(e.target.value)} name="segundonombre"></input>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="primer_apellido"><b style={{ color: "Red" }}>*</b>  Primer Apellido</label>
                                        <input className="form-control" type="text" value={primerApellido} placeholder="Primer Apellido" onChange={e => setPrimerapellido(e.target.value)} name="primerapellido"></input>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="segundo_apellido">Segundo Apellido</label>
                                        <input className="form-control" type="text" value={segundoApellido} placeholder="Segundo Apellido" onChange={e => setSegundoapellido(e.target.value)} name="segundopellido"></input>
                                    </div>
                                </form>
                                <p className="text-center"><b style={{ color: colores }}> Formulario N°2</b></p>
                            </CardBody>
                        </Card>
                    }
                    {/*
            ::::::::::: :::    ::: :::::::::  ::::::::::: ::::::::: ::::::::::: ::::::::      :::
            :+:     :+:    :+: :+:    :+:     :+:     :+:    :+:    :+:    :+:    :+:   :+: :+:
            +:+     +:+    +:+ +:+    +:+     +:+     +:+    +:+    +:+    +:+         +:+   +:+
            +#+     +#+    +:+ +#++:++#:      +#+     +#+    +:+    +#+    +#+        +#++:++#++:
            +#+     +#+    +#+ +#+    +#+     +#+     +#+    +#+    +#+    +#+        +#+     +#+
            #+# #+#     #+#    #+# #+#    #+#     #+#     #+#    #+#    #+#    #+#    #+# #+#     #+#
            #####       ########  ###    ### ########### ######### ########### ########  ###     ###
            */}
                    {tipoPersona === 'juridica' &&
                        <Card>
                            <CardBody>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="tipodocumento"><b style={{ color: "Red" }}>*</b> Tipo de identificación</label>
                                        <select className="form-control" id="tipodocumento" value={tipoDocumento} name="tipoDocumento" onChange={e => setDocumento(e.target.value)}>
                                            <option>Seleccione un tipo de documento</option>
                                            <option value="NIT" >NIT</option>
                                            <option value="Otro">Otro</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="identi"><b style={{ color: "Red" }}>*</b> Número de identificación</label>
                                        {(tipoDocumento === 'NIT') &&
                                            <input className="form-control" type="number" value={identificacion} onChange={e => setIdentificacion(e.target.value)} name="identificacion"></input>
                                        }
                                        {(tipoDocumento === 'Otro') &&
                                            <input className="form-control" type="text" value={identificacion} onChange={e => setIdentificacion(e.target.value)} name="identificacion"></input>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="empresa"><b style={{ color: "Red" }}>*</b>  Razón social</label>
                                        <input className="form-control" type="text" value={primerNombre} onChange={e => setPrimernombre(e.target.value)} name="empresa"></input>
                                    </div>
                                </form>
                                <p className="text-center"><b style={{ color: colores }}> Formulario N°2</b></p>
                            </CardBody>
                        </Card>
            /*
            .########..#######..########..##.....##.....#######.
            .##.......##.....##.##.....##.###...###....##.....##
            .##.......##.....##.##.....##.####.####...........##
            .######...##.....##.########..##.###.##.....#######.
            .##.......##.....##.##...##...##.....##...........##
            .##.......##.....##.##....##..##.....##....##.....##
            .##........#######..##.....##.##.....##.....#######.
                    */}
                    <Card>
                        <CardBody>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="email"><b style={{ color: "Red" }}>*</b>  Email</label>
                                    <input className="form-control" type="email" value={email} placeholder="Correo Electrónico" onChange={e => setEmail(e.target.value)} name="email"></input>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="fijo"><b style={{ color: "Red" }}>*</b>Teléfono Fijo</label>
                                    <input className="form-control" type="number" value={fijo} placeholder="Teléfono Fijo" onChange={e => setFijo(e.target.value)} name="fijo"></input>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="celular"><b style={{ color: "Red" }}>*</b>Teléfono Movil</label>
                                    <input className="form-control" type="number" value={celular} placeholder="Teléfono Movil" onChange={e => setCelular(e.target.value)} name="celular"></input>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="direccion"><b style={{ color: "Red" }}>*</b> Dirección</label>
                                    <input className="form-control" type="text" value={direccion} placeholder="Dirección" onChange={e => setDireccion(e.target.value)} name="direccion"></input>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pais"><b style={{ color: "Red" }}>*</b> País</label>
                                    <select className="form-control" id="pais" value={pais} name="pais" onChange={e => consultarDepto(e.target.value)}>
                                        <option>Seleccione el país</option>
                                        <option value="46">Colombia</option>
                                        {
                                            paises.map((pais) =>
                                                <option key={pais.id} value={pais.name}>{pais.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                {pais !== '46' && pais !== 'Colombia' &&
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="departamento1"><b style={{ color: "Red" }}>*</b> Provincia</label>
                                            <input className="form-control" type="text" onChange={e => setDepartamento(e.target.value)} name="departamento1"></input>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="municipio1"><b style={{ color: "Red" }}>*</b> Ciudad</label>
                                            <input className="form-control" type="text" onChange={e => setMunicipio(e.target.value)} name="municipio1"></input>
                                        </div>

                                    </div>
                                }
                                {(pais === '46' || pais === 'Colombia') &&
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="departamento1"><b style={{ color: "Red" }}>*</b> Departamento</label>
                                            <select className="form-control" id="departamento1" name="departamento1" onChange={e => consultarMunic(e.target.value)}>
                                                <option>Seleccione el departamento</option>
                                                {departamentos.map((depto) => {
                                                    return (
                                                        <option key={depto.id} value={depto.id}>{depto.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="municipio1"><b style={{ color: "Red" }}>*</b> Municipio</label>
                                            <select className="form-control" id="municipio1" name="municipio1" onChange={e => setMunicipio(e.target.value)}>
                                                <option>Seleccione el municipio</option>
                                                {municipios.map((municipio) => {
                                                    return (
                                                        <option key={municipio.id} value={municipio.name + "," + municipio.department.name}>{municipio.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                }
                            </form>
                            <h6 className="title text-center">La respuesta a su solicitud le será enviada a través de la dirección de correspondencia o al correo electrónico,
                                por lo tanto verifique que los datos se incluyeron correctamente.</h6>
                            <p className="text-center"><b style={{ color: colores }}> Formulario N°3</b></p>
                        </CardBody>
                    </Card>

                    {/*
            .########..#######..########..##.....##....##.......
            .##.......##.....##.##.....##.###...###....##....##.
            .##.......##.....##.##.....##.####.####....##....##.
            .######...##.....##.########..##.###.##....##....##.
            .##.......##.....##.##...##...##.....##....#########
            .##.......##.....##.##....##..##.....##..........##.
            .##........#######..##.....##.##.....##..........##.
            */}
                    <Card>
                        <CardBody>
                            <form>
                                <h6 className="title text-center">En el caso que la solicitud genere costos, estos serán informados posteriormente según la tarifa notarial vigente.</h6>
                                <div className="form-group">
                                    <label htmlFor="solicitud"><b style={{ color: "Red" }}>*</b> Tipo de Solicitud</label>
                                    <select className="form-control" id="solicitud" value={tipoSolicitud} name="solicitud" onChange={e => setSolicitud(e.target.value)}>
                                        <option>Seleccione el tipo de solicitud</option>
                                        {
                                            solicitudes.map((solicitud) =>
                                                <option key={solicitud.id} value={solicitud.id}>{solicitud.peticion}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="respuesta"><b style={{ color: "Red" }}>*</b> Medio de Respuesta</label>
                                    <select className="form-control" id="respuesta" value={tipoRespuesta} name="tipoRespuesta" onChange={e => setRespuesta(e.target.value)}>
                                        <option>Seleccione el medio de respuesta</option>
                                        <option value="Correo electrónico" >Correo electrónico</option>
                                        <option value="Dirección residencial" >Dirección residencial</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="detalle"><b style={{ color: "Red" }}>*</b>  Descripción solicitud</label>
                                    <textarea className="form-control" id="detalle" rows="3" value={detalle} onChange={e => setdetalle(e.target.value)} maxLength="300"></textarea>
                                    <p><i style={{ fontSize: "12px" }}>Puede ingresar hasta un máximo de 300 caracteres.</i></p>
                                </div>
                                <input className="form-control" multiple type="file" onChange={e => archivar(e)} name="archivo" accept=".jpg,.jpeg,.gif,.png,.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar"></input>
                                <h6 className="title text-center">Formatos válidos: Imagen (jpg,jpeg,gif,png), Texto (xls,xlsx,doc,docx,pdf), compresión de archivos (zip, rar). 3 archivos máximo, para más documentos subirlos en un .zip/.rar</h6>
                                <p className="text-center"><b style={{ color: colores }}> Formulario N°4</b></p>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
                <Row>
                    <Col>
                        {/*
            .########..#######..########..##.....##.....#######.
            .##.......##.....##.##.....##.###...###....##.....##
            .##.......##.....##.##.....##.####.####....##.......
            .######...##.....##.########..##.###.##....########.
            .##.......##.....##.##...##...##.....##....##.....##
            .##.......##.....##.##....##..##.....##....##.....##
            .##........#######..##.....##.##.....##.....#######.
            */}
                        <Card>
                            <CardBody>
                                <form>
                                    <div className="form-group">
                                        <div className="form-check form-check-inline">
                                            
                                            <label
                                            >
                                                Al hacer click en el botón enviar, usted acepta la remisión de la PQRS ala entidad. Sus datos seran recoleptados y tratados conforme a la Politica de Tratamiento de Datos.    
                                            </label>
                                            <label className="form-check-label">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value={check1} onChange={e => setCheck1(true)}></input>
                                                He leído y estoy de acuerdo con los términos y condiciones de uso de datos, implementados por la Notaria {nombreNotaria}.
                                                <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                                            </label>
                                            
                                        </div>
                                        <a href={terminosNotaria} rel="noreferrer" target="_blank" style={{ textDecorationLine: "none", color: "#2c2c2c" }}>
                                            Ver términos y condiciones.
                                        </a>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-check form-check-inline">
                                            <label className="form-check-label">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value={check2} onChange={e => setCheck2(true)}></input>
                                                Certifico que los datos de contacto ingresados en mis datos personales se encuentra vigentes, de igual manera autorizo a la Notaría {nombreNotaria} para el envío de la respuesta a mi solicitud por este medio.
                                                <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group text-center">
                                        <center>
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey="6LfA8OAZAAAAAOLznb-7W8ST5oHnunT9V4uD7rzf"
                                            />
                                        </center>
                                    </div>
                                    {mensajeAyuda.mostrar &&
                                        <Row className="justify-content-center">
                                            <Col ms="6">
                                                <Alert color={mensajeAyuda.tipo}>
                                                    <b style={{ fontSize: "16px" }}>{mensajeAyuda.message}</b>
                                                </Alert>
                                            </Col>
                                        </Row>
                                    }
                                    <div className="submit text-center">
                                        <Button
                                            color="warning"
                                            className="btn-raised btn-round"
                                            defaultValue="Enviar"
                                            //type="submit"
                                            onClick={(e) => enviarCorreo(e)}
                                        >
                                            <b style={{ fontSize: "16px" }}>Enviar Registro</b>
                                        </Button>
                                    </div>
                                </form>
                                <p className="text-center"><b style={{ color: colores }}> Formulario N°5</b></p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Row>
        </>

    )
}

export default Pqrs;