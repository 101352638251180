import React from "react";

// reactstrap components
import {  Modal,
          ModalBody,
          ModalFooter,
          Button,
} from "reactstrap";

// core components

function Organigrama(props) {

  return (
    <>
      <Modal isOpen={props.modal} toggle={props.setModal} className="modal-lg modal-dialog modal-dialog-centered" size="lg">
        <div style={{backgroundColor: "White" ,borderRadius: "15px"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="btn btn-primary btn-round"
                type="button"
                onClick={props.setModal}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h3 className="title text-center" style={{color:"#2c2c2c"}} id="exampleModalLiveLabel">
                  Organigrama
                </h3>
                <img
                  alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                  src={require("assets/img/NOTARIA/organigrama.jpg")}
                ></img>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    type="button"
                    onClick={props.setModal}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    </>
  );
}

export default Organigrama;
