import React from "react";

// reactstrap components
import {  Modal,
          ModalBody,
          ModalFooter,
          Button,
          Row,
          Col,
          Card,
          CardBody
} from "reactstrap";

// core components

function Funciones(props) {

  return (
    <>
      <Modal isOpen={props.modal} toggle={props.setModal} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="btn btn-primary btn-round"
                type="button"
                onClick={props.setModal}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h3 className="title text-center" style={{color:"#2c2c2c"}} id="exampleModalLiveLabel">
                  Funciones y deberes
                </h3>
                <Row>
                <Col md="6">
                  <Card style={{padding: "15px 15px 15px 15px"}}>
                    <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                      <h4 className="text-center">Decreto 960 de 1970</h4>
                      <a href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html" 
                        role="button" className="btn btn-warning" target="_black" aria-disabled="true">
                         <b style={{fontSize: "16px"}}>Ver información</b>
                      </a>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card style={{padding: "15px 15px 15px 15px"}}>
                    <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                      <h5 className="text-center">Decreto 1069 de 2015 sector justicia y del derecho</h5>
                      <a href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174" 
                        style={{marginBottom: "20px"}}
                         role="button" className="btn btn-warning"  target="_black" aria-disabled="true">
                         <b style={{fontSize: "16px"}}>Ver información</b>
                      </a>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                  color="primary"
                  type="button"
                  onClick={props.setModal}
                  className="text-center"
              >
                  <b style={{fontSize: "17px"}}>Cerrar</b>
              </Button>
            </ModalFooter>
        </div>
    </Modal>
    </>
  );
}

export default Funciones;
