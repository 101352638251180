import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Politicas/Politicas.js";


function PoliticasPrivacidadWeb() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <div id="servicio" style={{ backgroundColor: "#f9f9f9" }}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="title text-center"><b style={{ color: "#ef7e2d" }}>Políticas de </b>Privacidad Web</h1>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col md="4">
                        <center>
                          <button type="button" className="btn btn-outline-light">
                            <a  href={require("../assets/pdf/Manual-de-Politicas-de-Privacidad-Web.pdf")} style={{textDecoration: "none !important"}} target="_black">
                              <img
                                alt="Imagen con referencia Privacidad Web"
                                width="80px"
                                src={require("assets/img/NOTARIA/pdf.png")}
                              ></img>
                              <h6>Politica de Privacidad Web</h6>
                            </a>
                          </button>
                        </center>
                      </Col>
                      <br></br>
                      <Col md="4">
                        <center>
                          <img
                            alt="Certificado AWS"
                            width="400px"
                            src={require("assets/img/NOTARIA/Certificatehttps.png")}
                          ></img>
                          <h6>Certificado Seguridad AWS</h6>
                        </center>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default PoliticasPrivacidadWeb;
