import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Card,
  CardBody,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
  Col
} from "reactstrap";

// core components
function Servicios() {

  return (
    <>
      <div
        className="section" id="servicios"
      >
        <Container>
          <Row className="justity-content-center" >
            <Col md="12">
              <h2 className="text-center title">
                <b style={{ color: "#ef7e2d" }}>Le da la bienvenida a su portal web y le</b> ofrece los siguientes servicios.<br></br>
              </h2>
              <div className="space-50"></div>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Card className="card-plain card-blog">
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="Dos personas dialogando mientras una de ella firma un documento"
                      className="img rounded img-raised"
                      src={require("assets/img/NOTARIA/es.jpg")}
                    ></img>
                  </a>
                </div>
                <CardBody>
                  <h4 className="category text-warning">Escrituración</h4>
                  <p className="card-description"><b>
                    Documento que contiene la declaración de voluntad de una
                    o varias personas, emitidas ante el notario, para hacer
                    un determinado contrato o un acto jurídico individual.{" "}
                  </b>
                    <a href="#pablo" id="p1" onClick={(e) => e.preventDefault()}>
                      <b>Requisitos</b>
                    </a>
                  </p>
                  <UncontrolledPopover trigger="legacy" placement="bottom" target="p1">
                    <PopoverHeader><p style={{ fontSize: "18px", color: "Black" }}><b>Requisitos</b></p></PopoverHeader>
                    <PopoverBody>
                      <p><i className="now-ui-icons arrows-1_minimal-right"></i> Asistir personalmente a la notaría.</p>
                      <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar cédula de ciudadanía.</p>
                      <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar documento privado que se va a firmar.</p>
                      <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar testigo cuando así se requiera.</p>
                    </PopoverBody>
                  </UncontrolledPopover>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-plain card-blog">
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="Dos personas dialogando mientras una de ella firma un documento"
                      className="img rounded img-raised"
                      src={require("assets/img/NOTARIA/re.png")}
                    ></img>
                  </a>
                </div>
                <CardBody>
                  <h4 className="category text-warning">Registro Civil</h4>
                  <p className="card-description"><b>
                    Es un documento indispensable mediante el cual la persona prueba ante
                    la familia y la sociedad su existencia y le permite identificarse,
                    ejercer sus derechos y cumplir sus obligaciones.{" "}</b>
                    <a href="#pablo" id="p2" onClick={(e) => e.preventDefault()}>
                      <b>Requisitos</b>
                    </a>
                  </p>
                  <UncontrolledPopover trigger="legacy" placement="bottom" target="p2">
                    <PopoverHeader><p style={{ fontSize: "18px", color: "Black" }}><b>Requisitos</b></p></PopoverHeader>
                    <PopoverBody>
                      <p><i className="now-ui-icons arrows-1_minimal-right"></i> Certificado de nacido vivo.</p>
                      <p><i className="now-ui-icons arrows-1_minimal-right"></i> Documento de identificación de los padres.</p>
                      <i>La inscripción del registro civil no tiene costo.</i>
                    </PopoverBody>
                  </UncontrolledPopover>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-plain card-blog">
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="Dos personas indicando con sus lapiceros una sección de un documento"
                      className="img rounded img-raised"
                      src={require("assets/img/NOTARIA/au.jpg")}
                    ></img>
                  </a>
                </div>
                <CardBody>
                  <h4 className="category text-warning">Autenticaciones</h4>
                  <p className="card-description">
                    <b>Es cuando el notario da testimonio escrito de las firma que aparecen
                      en un documento privado fueron puestas en su presencia, estableciendo
                      la identidad de los firmantes.</b>{" "}
                    <a href="#pablo" id="p3" onClick={(e) => e.preventDefault()}>
                      <b>Requisitos</b>
                    </a>
                  </p>
                  <UncontrolledPopover trigger="legacy" placement="bottom" target="p3">
                    <PopoverHeader><p style={{ fontSize: "18px", color: "Black" }}><b>Requisitos</b></p></PopoverHeader>
                    <PopoverBody>
                      <p><i className="now-ui-icons arrows-1_minimal-right"></i> Asistir personalmente a la notaría. Puede hacerse también a domicilio</p>
                      <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar cédula de ciudadanía.</p>
                      <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar documento que se va a autenticar.</p>
                    </PopoverBody>
                  </UncontrolledPopover>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Servicios;
