import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody,
    CardText
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Notario/Notario.js";


function Notario() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="title text-center"><b style={{color: "#ef7e2d"}}>Biografía</b> del Notario</h1>
              </Col>
            </Row> 
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md ="4">
                        <Card>
                          <CardBody>
                            <CardText className="text-center" >
                              <b>Dr. ÓSCAR FERNANDO MARTÍNEZ BUSTAMANTE</b>
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="8">
                        <Row>
                          <Col md="5">
                            <Card>
                              <CardBody>
                                <h5><b style={{color: "#ef7e2d"}}>País: </b>Colombia</h5>
                                <h5><b style={{color: "#ef7e2d"}}>Departamento: </b>Magdalena</h5>
                               <h5><b style={{color: "#ef7e2d"}}>Ciudad: </b>Plato</h5>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col md="7">
                            <Card>
                              <CardBody>
                                <h5><b style={{color: "#ef7e2d"}}>Correo: </b>notaria26bogota@ucnc.com.co</h5>
                                <h5><b style={{color: "#ef7e2d"}}>Dirección: </b>Carrera 12 #93-26 Bogotá, Cundinamarca</h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card>
                              <CardBody>
                                <h4><b style={{color: "#ef7e2d"}}>Formación Académica y Experiencia Laboral</b></h4>
                                <h5 className="text-justify">Abogado de la Universidad Externado de Colombia. Especialista en Derecho Administrativo de la Universidad Santo Tomás. 
                                  Especialista en Gerencia de Negocios Internacionales de la Universidad Jorge Tadeo Lozano. Diploma de Estudios 
                                  Avanzados (Derecho Constitucional – El Parlamento en una Democracia en Transformación) de la Universidad Complutense 
                                  de Madrid (España). Profesor Universitario en Derecho Constitucional. Ex Notario 54 y 66 del Círculo de Bogotá</h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <center><h4><b style={{color: "#ef7e2d"}}>Publicaciones</b></h4></center>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col md="2">
                        <center>
                          <button type="button" className="btn btn-outline-light">
                            <a  href={require("../assets/pdf/NOTA.pdf")} style={{textDecoration: "none !important"}} target="_black">
                              <img
                                alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                                width="80px"
                                src={require("assets/img/NOTARIA/pdf.png")}
                              ></img>
                              <h6>‘Blockchain’: ¿adiós a los notarios?</h6>
                            </a>
                          </button>
                        </center>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Notario;