import React from "react";

import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Card,
  CardBody
} from "reactstrap";

const items = [
  {
    id: 1,
    caption1: "Pasarela de Pagos",
    boton: "Pagos",
    text: "En este módulo podrá realizar el pago de los diferentes servicion notariales ofrecidos.",
    url: "/pagos"
  },
  {
    id: 2,
    caption1: "Cotizador online",
    boton: "Cotización",
    text: "En este módulo podrá realizar las cotizaciones previas a sus escrituras de: Compraventa, Compraventa e hipoteca con particulares y/o entidades financieras, Hipotecas, cancelaciones de hipoteca, matrimonios y sucesiones, entre otras.",
    url: "/cotizador"
  }
  
];

function Linea() {

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <div className="section" id="cotizador" style={{ backgroundColor: "#F2F2F2BA" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5">
              <br></br>
              <Card className="text-center" style={{ margin: "0", backgroundColor: "#ef7e2d" }}>
                {/* paddingTop: "15px", paddingBottom: "15px" */}
                <CardBody>
                  <h1 className="title" style={{ color: "#ffffff", paddingTop: "15px" }}>
                    Noticias
                  </h1>
                  {/* <h3 className="description" style={{color: "#ffffff"}}>
                <b>En esta sección encontrará aplicativos online 
              para agilizar sus procesos notariales</b>
              </h3> */}
                  {/* <p className="card-description" style={{color: "Black"}}><b>En esta sección encontrará aplicativos online 
              para agilizar sus procesos notariales</b></p> */}
                </CardBody>
              </Card>
            </Col>
            <Col lg="7">
              <br></br>
              <Carousel activeIndex={activeIndex} next={next} previous={previous} style={{ borderRadius: "15px" }}>
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map(item => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.id}
                    >
                      <Card className="text-center" style={{ margin: "0", paddingRight: "25px", paddingLeft: "25px" }}>
                        <CardBody>
                          <h2 className="title" style={{ color: "Black" }}>{item.caption1}</h2>
                          <div className="text-center">
                            <p className="card-description" style={{ color: "Black" }}><b>{item.text}</b></p>
                            {item.url &&
                              <a href={item.url} className="btn btn-warning btn-lg" aria-disabled="true" role="button">
                                <b style={{ fontSize: "16px" }}>{item.boton}</b>
                              </a>
                            }
                            {item.src && <img src={item.src} alt="item" />}

                            {/* <Button className="btn-round" color="info" type="button">
                            <b style={{fontSize: "16px"}}>{item.boton}</b>
                          </Button> */}
                          </div>
                        </CardBody>
                      </Card>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                  style={{ width: "10%" }}
                >
                  <i className="now-ui-icons arrows-1_minimal-left" style={{ color: "Black" }}></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                  style={{ width: "10%" }}
                >
                  <i className="now-ui-icons arrows-1_minimal-right" style={{ color: "Black" }}></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Linea;
