import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';

// styles
import 'bootstrap/dist/css/bootstrap.min.css';
// import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";

import PresentationHeader from "components/Headers/PresentationHeader";
import { DropdownWhiteNavbar } from "components/Navbars";
import { FooterBlack } from "components/Footers";
import Whatsapp from "views/presentation-sections/Whatsapp";
import Presentation from "views/Presentation";
import Tramites from "views/Tramites";
import Cotizador from "views/Cotizador";
import Solicitudes from "views/Solicitudes";
import Transparencias from "views/Transparencias";
import PoliticasDatosPersonales from "views/PoliticasDatosPersonales";
import TerminoCondiciones from "views/TerminosCondiciones";
import MapaSitio from "views/MapaSitio";
import PoliticasDerechosAutor from "views/PoliticasDerechosAutor";
import PoliticasPrivacidadWeb from "views/PoliticasPrivacidadWeb";
import CertificadoAccesibilidad from "views/CertificadoAccesibilidad";
import Informacion from "views/Informacion";
import Miyvi from "views/Miyvi";
import { Edictos } from "views";
import Normativa from "views/Normativa";
import Notario from "views/Notario";
import Tarifas from "views/Tarifas";
import Formatos from "views/Formato";
import Proceso from "views/Procesos";
import ClasificadaReservada from "views/ClasificadaReservada";
import GestionDocumental from "views/GestionDocumental";
import GestionInformacion from "views/GestionInformacion";
import RetencionDocumental from "views/RetencionDocumental";
import AfectarPublico from "views/AfectarPublico";
import Mujeres from "views/Mujeres";
import Otrosin from "views/Otrosin";
import Pagos from "views/Pagos";

// Menu Participa
import Participa from "views/menu-participa/Participa.js";
import Diagnostico from "views/menu-participa/diagnostico-identificacion-problemas.js";
import Planeacion from "views/menu-participa/planeacion-presupuesto-participativo.js";
import Rendicion from "views/menu-participa/rendicion-cuenta.js";
import Consulta from "views/menu-participa/consulta-ciudadana";
import Control from "views/menu-participa/control-social";
import Colaboracion from "views/menu-participa/colaboracion-innovacion-abierta";
import MapaProcesos from "views/MapaProcesos.js"

import "config"


ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <div className='dark-mode'>
        <DropdownWhiteNavbar />
        <PresentationHeader />
        <Routes>
          <Route
            path="/formato"
            element={<Formatos />}
          />
          <Route
            path="/otrosin"
            element={<Otrosin />}
          />
          <Route
            path="/mujeres"
            element={<Mujeres />}
          />
          <Route
            path="/edictos"
            element={<Edictos />}
          />
          <Route
            path="/tarifa"
            element={<Tarifas />}
          />
          <Route
            path="/notario"
            element={<Notario />}
          />
          <Route
            path="/normativa"
            element={<Normativa />}
          />
          <Route
            path="/procesos"
            element={<Proceso />}
          />
          <Route
            path="/mision_vision"
            element={<Miyvi />}
          />
          <Route
            path="/informacion"
            element={<Informacion />}
          />
          <Route
            path="/PoliticasDatosPersonales"
            element={<PoliticasDatosPersonales />}
          />
          <Route
            path="/PoliticasDerechosAutor"
            element={<PoliticasDerechosAutor />}
          />
          <Route
            path="/PoliticasPrivacidadWeb"
            element={<PoliticasPrivacidadWeb />}
          />
          <Route
            path="/CertificadoAccesibilidad"
            element={<CertificadoAccesibilidad />}
          />
          <Route
            path="/TerminosCondiciones"
            element={<TerminoCondiciones />}
          />
          <Route
            path="/MapaSitio"
            element={<MapaSitio />}
          />
          <Route
            path="/participa" 
            element={<Participa/>}
          />
          <Route
            path="/participacion-para-el-diagnostico-de-necesidades-e-identificacion-de-problemas" 
            element={<Diagnostico/>}
          />
          <Route
            path="/planeacion-y-presupuesto-participativo" 
            element={<Planeacion/>}
          />
          <Route
            path="/consulta-ciudadana" 
            element={<Consulta/>}
          />
          <Route
            path="/rendicion-de-cuentas" 
            element={<Rendicion/>}
          />
          <Route
            path="/control-social" 
            element={<Control/>}
          />
          <Route
            path="/colaboracion-e-innovacion-abierta" 
            element={<Colaboracion/>}
          />
          <Route
            path="/transparencia/:modalNombre"
            element={<Transparencias />}
          />
          <Route
            path="/peticiones"
            element={<Solicitudes />}
          />
          <Route
            path="/cotizador"
            element={<Cotizador />}
          />
          <Route
            path="/tramite"
            element={<Tramites />}
          />
          <Route
            path="/ClasificadaReservada"
            element={<ClasificadaReservada />}
          />
          <Route
            path="/GestionDocumental"
            element={<GestionDocumental />}
          />
          <Route
            path="/GestionInformacion"
            element={<GestionInformacion />}
          />
          <Route
            path="/RetencionDocumental"
            element={<RetencionDocumental />}
          />
          <Route
            path="/AfectarPublico"
            element={<AfectarPublico />}
          />
          <Route
            path="/pagos"
            element={<Pagos />}
          />
          <Route
            path="/MapaProcesos"
            element={<MapaProcesos />}
          />
          <Route
            path="/"
            element={<Presentation />}
          />
          {/*<Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
        <Whatsapp />
        <FooterBlack />
      </div>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
