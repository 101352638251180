import React from "react";

// reactstrap components
import {  Modal,
          ModalBody,
          ModalFooter,
          Button,
          Row,
          Col,
          Card,
          CardBody
} from "reactstrap";

// core components

function Tramites(props) {

  return (
    <>
      <Modal isOpen={props.modal} toggle={props.setModal} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px"}}>
            <div className="modal-header">
              <h3> </h3>
              <button
              aria-label="Close"
              className="btn btn-primary btn-round"
              type="button"
              onClick={props.setModal}
              >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
              </button>
            </div>           
            <ModalBody>
            <h3 className="title text-center" style={{color:"#2c2c2c"}} id="exampleModalLiveLabel">
                  Procedimientos y protocolos de atención
              </h3>
              <Row>
                <center>
                  <Col md="12">
                    <Card style={{padding: "15px 15px 15px 15px"}}>
                      <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                        <h4 className="text-center">Protocolos de atención</h4>
                        <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf" 
                          style={{marginBottom: "10px"}}
                            role="button" className="btn btn-warning"  target="_black" aria-disabled="true">
                            <b style={{fontSize: "16px"}}>Ver información</b>
                        </a>
                      </CardBody>
                    </Card>
                  </Col> 
                </center>                              
              </Row> 
            </ModalBody>
            <ModalFooter>
              <Button
                  color="primary"
                  type="button"
                  onClick={props.setModal}
                  className="text-center"
              >
                  <b style={{fontSize: "17px"}}>Cerrar</b>
              </Button>
            </ModalFooter>
        </div>
    </Modal>
    </>
  );
}

export default Tramites;
