import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardHeader,
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Procesos/Procesos.js";


function Pagos() {

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
    
  });
  function valor()  {
    //document.getElementById("public-key").value = "prv_test_ujI7n19UQ0MHKZh4AavxX98Gd59KjFM8"
    var centavos = document.getElementById('amount-in-cents').value + '00'
    document.getElementById('amount-in-cents').value = centavos
    var tipoDoc = document.getElementById('tipoDoc').value
    document.getElementById('customer-data:legal-id-type').value = tipoDoc

  }

  return (
    <>
      <div className="wrapper">
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="title text-center"><b style={{color: "#ef7e2d"}}>Pasarela de pagos</b></h1>
              </Col>
            </Row> 
            <Row>
              <Col className="ml-auto mr-auto" lg="6">
                <Card style={{ backgroundColor:"white"}}>
                  <CardHeader style={{ }}>
                    <h4 className="info-title text-center" style={{marginTop: "20px", color:"black"}}><b>Ingrese el valor exacto del servicio y de click en pagar para ir a la pasarela de pagos de WOMPI</b></h4>
                  </CardHeader>
                </Card>
              </Col>
              <br></br>
              <Col className="ml-auto mr-auto" lg="6">
                <Card style={{ backgroundColor:"white"}}>
                  <CardHeader style={{ textAlign: 'center'}}>
                    <form action="https://checkout.wompi.co/p/"method="GET" > 
                      <input type="hidden" name="public-key" id="key" value="pub_prod_9yIOiS2rt1L7qxQvX54JUcaygvhgh0xP"/>
                      <label style={{ color:"orange"}}>Tipo de Documento</label><br></br>
                      <select id="tipoDoc">
                        <option>Seleccione Tipo de Documento</option>
                        <option value="CC">Cedula de ciudadanía</option>
                        <option value="CE">Cedula de Extranjeria</option>
                        <option value="NIT">NIT</option>
                      </select>
                      <input type="hidden" name="customer-data:legal-id-type" id="customer-data:legal-id-type"/><br></br>
                      <br></br>
                      <label style={{ color:"orange"}}>Documento de identificación</label><br></br>
                      <input type="text" name="customer-data:legal-id" id="customer-data:legal-id" required/><br></br>
                      <input type="hidden" name="currency" id="currency" value="COP"/>
                      <br></br>
                      <label style={{ color:"orange"}}>Valor del Servicio</label><br></br>
                      <input type="text" name="amount-in-cents" id="amount-in-cents" required/><br></br>
                      <br></br>
                      <label style={{ color:"orange"}}>Codigo de Referencia</label><br></br>
                      <input type="text" name="reference" id="reference" required/><br></br>
                      <br></br>
                      <label style={{ color:"orange"}}>Nombre Completo</label><br></br>
                      <input type="text" name="customer-data:full-name" required/><br></br>
                      <br></br>
                      <label style={{ color:"orange"}}>Numero Telefono</label><br></br>
                      <input type="text" name="customer-data:phone-number" required/><br></br>
                      <br></br>
                      <img
                        alt="Imagen con referencia hacia un icono para descargar un archivo PNG"
                        width="80px"
                        src={require("assets/img/NOTARIA/WompiLogo.png")}>
                      </img>
                      <br></br>
                      <button type="submit" value="submit"  onClick={valor} style={{ color:"orange", fontWeight:"bold"}} >Pagar Con Wompi</button>
                    </form>
                  </CardHeader>
                </Card>  
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
  
  
}

export default Pagos;