import React from "react";

// reactstrap components
import { Container, 
  Row, 
  Col, 
  Carousel, 
  CarouselItem, 
  CarouselIndicators,
  Card,
  CardBody,
  Button
} from "reactstrap";

// core components
const items = [
  {
    src: require("assets/img/NOTARIA/notaprincipal.png"),
  },
  {
    src: require("assets/img/NOTARIA/nota2.JPG"),
  },
  {
    src: require("assets/img/NOTARIA/nota3.png"),
  }
];

function Galeria() {

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <div className="section" id="galeria">
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" md="12">
              <h1 className="title"><b style={{color:"#ef7e2d"}}>Nuestras</b> Oficinas</h1>
              <h4 className="description" style={{color: "Black"}}><b>Tenemos un ambiente adecuado para que usted realice sus trámites.</b></h4>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="10" lg="10">
              <Card>
                <CardBody>
                  <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                    <CarouselIndicators
                      items={items}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />
                    {items.map(item => {
                      return (
                        <CarouselItem
                          onExiting={onExiting}
                          onExited={onExited}
                          key={item.src}
                        >
                          <img src={item.src} alt={item.altText} width="100%"/>
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous();
                      }}
                      role="button"
                    >
                        <Button
                        className="btn-icon btn-round"
                        color="warning"
                        name="button"
                        size="sm"
                        type="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-left"></i>
                        </Button>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next();
                      }}
                      role="button"
                    >
                        <Button
                        className="btn-icon btn-round"
                        color="warning"
                        name="button"
                        size="sm"
                        type="button"
                        >
                        <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </Button>
                    </a>
                  </Carousel>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Galeria;
