import React from "react";

import { Container, NavbarBrand } from "reactstrap";
import logo from "../../../src/assets/img/NOTARIA/logo.png";
import { Link } from "react-router-dom";

const FooterBlack = () => {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="copyright float-left">
            <a href="/TerminosCondiciones">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Términos y condiciones</h6>
            </a>
            <a href="/PoliticasDatosPersonales">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Política de Tratamiento de Datos Personales</h6>
            </a>
            <a href="/PoliticasDerechosAutor">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Politícas de derechos de autor</h6>
            </a>
            <a href="/CertificadoAccesibilidad">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Certificado de Accesibilidad</h6>
            </a>
            <a href="/PoliticasPrivacidadWeb">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Políticas de Privacidad web</h6>
            </a>
            <a href="/MapaSitio">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Mapa del Sitio</h6>
            </a>
            <a href="/#contacto">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Canales de Atencion al Publico</h6>
            </a>
            <a href="/#contacto">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Telefono:  57+(1) 328 2121</h6>
            </a>
          </div>
          <div className="copyright" id="copyright"  >
            <NavbarBrand to="/" tag={Link} id="navbar-brand" style={{ alignItems: 'center' }}>
              <img style={{ backgroundColor: 'white', borderRadius: '10px' }} src={logo} alt="Logo de la notaria 26 de bogota" width="200px"></img>
            </NavbarBrand>
          </div>
        </Container>
        <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#474747', width: '100%', height: '40px', display: 'flex', alignItems: 'center', paddingLeft: '30px', justifyContent: 'left' }}>
            <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
              <img
                alt="Logo de Instagram"
                src={require("assets/img/NOTARIA/instagram.jpg")} style={{ height: '40px', borderRadius: '15px' }}
              ></img>
            </a>
            <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
              <img
                alt="Logo de Twitter"
                src={require("assets/img/NOTARIA/twitter.png")} style={{ height: '40px', borderRadius: '15px' }}
              ></img>
            </a>
            <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
              <img
                alt="Logo de Facebook"
                src={require("assets/img/NOTARIA/facebook.png")} style={{ height: '40px', borderRadius: '15px' }}
              ></img>
            </a>
            © {new Date().getFullYear()}, Designed by{'\u00A0'}
            <a
              href="https://www.sinfony.com.co/sinfony/"
              rel="noreferrer"
            >
              SINFONY Todos los derechos reservados.
            </a>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
