/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col,Card,CardBody,CardTitle } from "reactstrap";
// core components

function PresentationHeader() {
  return (
    <>
      <Container fluid>
          <Row>
              <Card
                className="card-fashion page-header-image"
                style={{
                  backgroundSize: "100% 100%",
                  backgroundImage:
                    "url(" + require("assets/img/NOTARIA/notaprincipal.png") + ")",
                }}
              >
                <CardBody>
                  <CardTitle className="text-left" tag="div">
                  <div className="rellax-text-container rellax-text">
                    <h1 className="title" data-rellax-speed="-1" 
                        style={{marginTop: "-300px", 
                                fontSize: "500%", 
                                // background: "linear-gradient(180deg,#fff 35%,#75a6c3)",
                                // webkitBackgroundClip: "text",
                                // WebkitTextFillColor: "transparent"
                                }}>
                      Notaría 26 de Bogotá
                    </h1>
                    <h2 className="title" data-rellax-speed="-1">
                      SERVICIOS NOTARIALES
                    </h2>
                  </div>
                  </CardTitle>
                </CardBody>
              </Card>
          </Row>
      </Container>
      
    </>
  );
}

export default PresentationHeader;
