// import { Document, Page } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import './EdictoItem.css'

const EdictoItem = ({ titulo, nombre_cliente, fecha_inicio, fecha_expiracion, ruta_archivo }) => {

  return <div className="col-12 col-sm-6 col-md-4 col-lg-3">
    <div className="card">
      <div className="card-img-top d-flex">
        <Document
          className="w-100 d-flex justify-content-center align-items-center overflow-hidden"
          file={ruta_archivo}
          error={<object className="w-100" data={ruta_archivo} type="application/pdf" title={titulo} aria-label={titulo} ></object>}
          loading={<div className="spinner-border" role="status" />}
        >
          <Page pageNumber={1} scale={0.5} />
        </Document>
      </div>
      <div className="card-body">
        <h5 className="card-title">{titulo}</h5>
        <h6 className="card-subtitle mb-2 text-muted uc-words">{nombre_cliente} | {fecha_inicio}</h6>
        <a type="button" className="btn btn-link" href={ruta_archivo} target="_blank" rel="noopener noreferrer">Ver mas</a>        
      </div>
      {/* <a href={item} target="_blank" rel="noopener noreferrer" className="cover" >
            <h5 className="card-title">Card title</h5>
          </a> */}
    </div>
  </div>
}

export default EdictoItem