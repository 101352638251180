import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Miyvi/Miyvi.js";


function Miyvi() {

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  
  React.useEffect(() => {
    
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="title text-center"><b style={{color: "#ef7e2d"}}>Misión</b></h1>
              </Col>
            </Row> 
            <Row className="justify-content-center">
              <Col md="10">
                <Card>
                  <CardBody>
                      <h5 className="info text-center">
                        <b>LA NOTARÍA 26 DE BOGOTÁ</b> tiene como misión prestar un servicio orientado a la mayor seguridad 
                        jurídica de los actos que en ella se realicen, bajo altos estándares de eficiencia, agilidad y calidad, 
                        en forma que genere una alta satisfacción en los usuarios.
                      </h5>Versión 1
                  </CardBody>
                </Card>      
              </Col>
              <Col lg="12">
                <h1 className="title text-center"><b style={{color: "#ef7e2d"}}>Visión</b></h1>
              </Col>
              <Col md="10">
                <Card>
                  <CardBody>
                      <h5 className="info text-center">
                        En la <b>NOTARÍA 26 DE BOGOTÁ</b> nos proyectamos como institución integrada por  un equipo humano 
                        ético, altamente calificado y comprometido con un servicio amable y de calidad, que sea  un modelo 
                        de desarrollo tecnológico y de innovación en cada una de las áreas de servicio notarial, y que genere 
                        un reconocimiento positivo por los usuarios.
                      </h5>Versión 1
                  </CardBody>
                </Card>      
              </Col>
              <Col lg="12">
                <h1 className="title text-center"><b style={{color: "#ef7e2d"}}>Política de Calidad</b></h1>
              </Col>
              <Col md="10">
                <Card>
                  <CardBody>
                    <center>
                      <button type="button" className="btn btn-outline-light">
                        <a  href={require("../assets/pdf/ITEM 3.1 OG-01 V.3 DIRECCIONAMIENTO ESTRATEGICO(1).pdf")} style={{textDecoration: "none !important"}} target="_black">
                          <img
                            alt="Imagen con referencia Politica Calidad"
                            width="80px"
                            src={require("assets/img/NOTARIA/pdf.png")}
                          ></img>
                          <h6>Política de Calidad</h6>
                        </a>
                      </button>
                    </center>
                  </CardBody>
                </Card>      
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Miyvi;